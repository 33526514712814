import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const Objectives = () => {
  return (
    <div>
      <Layout>
        <SEO title="Objectives" />
        <PageStructure
          title="Objectives"
          desc="TESTIG"
          mode={ABOUTUS}
          content={`
        
        The objective of the Association is to promote and safeguard the general interests its members and generally to do all such other acts incidents or conducive to the attainment of the said objective.
        
        `}
        />
      </Layout>
    </div>
  )
}

export default Objectives
